













































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { ApiDto } from '@portals/shared/admin/ApiDto';
import { ApiIncidentDto } from '@portals/shared/admin/ApiIncidentDto';
import { format } from 'date-fns';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { fetchApiIncidents } from '@/service/apiIncidentService';
import { fetchApis } from '@/service/apiService';

interface ApiIncidentWithApiName extends ApiIncidentDto {
  apiName: string;
}

type Data = {
  apiIncidents: ApiIncidentDto[];
  apiIncidentsWithApiName: ApiIncidentWithApiName[];
  apis: ApiDto[];
  loading: boolean;
  create: boolean;
};

export default Vue.extend({
  name: 'list-api-incidents',
  components: {
    Loader,
    DnbIcon,
    TableComponent,
    TableColumn,
  },
  data(): Data {
    return {
      apiIncidents: [],
      apiIncidentsWithApiName: [],
      apis: [],
      loading: false,
      create: false,
    };
  },
  mounted() {
    this.loadApisAndApiIncidents();
  },
  methods: {
    async loadApisAndApiIncidents() {
      this.loading = true;
      this.apiIncidents = await fetchApiIncidents();
      this.apis = await fetchApis();
      const apiNameMap: { [key: string]: string } = this.apis.reduce(
        (accumulator, current) => {
          return { ...accumulator, [current.id]: current.name };
        },
        {},
      );
      this.apiIncidentsWithApiName = this.apiIncidents.map((apiIncident) => {
        return {
          ...apiIncident,
          apiName: apiNameMap[apiIncident.apiId],
        };
      });
      this.loading = false;
    },
    onRowClick(row: { data: ApiIncidentDto }) {
      this.$router.push(`/api-incidents/${row.data.id}/edit`);
    },
    dateFormatter(date: Date) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY');
    },
  },
});
