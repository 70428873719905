import {
  ApiIncidentDto,
  ApiIncidentInputDto,
} from '@portals/shared/admin/ApiIncidentDto';

import { request } from './request';

export async function fetchApiIncidents(): Promise<ApiIncidentDto[]> {
  const response = await request<ApiIncidentDto[]>({
    url: 'api-incidents',
  });
  return response.data;
}

export async function fetchApiIncident(id: string): Promise<ApiIncidentDto> {
  const response = await request<ApiIncidentDto>({
    url: `api-incidents/${id}`,
  });
  return response.data;
}

export async function saveApiIncident(
  apiIncidentInputDto: ApiIncidentInputDto,
): Promise<ApiIncidentDto> {
  const response = await request<ApiIncidentDto>({
    url: 'api-incidents',
    method: 'POST',
    data: apiIncidentInputDto,
  });

  return response.data;
}

export async function deleteApiIncident(id: string): Promise<void> {
  await request<ApiIncidentDto>({
    url: `api-incidents/${id}`,
    method: 'DELETE',
  });
}
